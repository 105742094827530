import React, { useEffect, useState } from 'react'

import { Link } from 'gatsby'

import Layout from '../components/layout'

import videoTable from '../constants/videoTable'

const VideosPage = () => {
  const [videoElements, setVideoElements] = useState([])

  useEffect(() => {
    const videoElements = []

    videoTable.forEach((video) => {
      videoElements.push(
        <div className='flex flex-col my-6'>
          <div className='flex flex-col lg:flex-row mx-auto my-2 text-center lg:text-left'>
            <Link
              to={`/play?id=${video.id}`}
              state={{ youtubeLink: video.youtubeLink }}
            >
              <div className='mb-2 max-w-xs mx-auto lg:hidden'>
                {video.title}
              </div>
              <img
                className='mx-auto lg:w-96'
                src={video.thumbnail}
                alt=''
              ></img>
              <div className='mt-2 max-w-xs mx-auto text-sm text-gray-700 hover:no-underline lg:hidden'>
                {video.description}
              </div>
            </Link>
            <div className='hidden lg:flex lg:flex-col'>
              <Link to={`/play?id=${video.id}`}>
                <div className='ml-8 mt-2 mb-4 w-80 text-xl hover:underline'>
                  {video.title}
                </div>
              </Link>
              <div className='ml-8 mt-0 w-96 text-base text-gray-700 hover:no-underline'>
                {video.description}
              </div>
            </div>
          </div>
        </div>
      )
    })
    setVideoElements(videoElements)
  }, [setVideoElements])

  return (
    <Layout>
      <div className='pageTitle'>Videos</div>
      <hr className='mx-8 mt-2 mb-8 md:my-4 md:mx-64 text-white border-gray-500' />
      <div className='flex flex-col'>{videoElements}</div>
    </Layout>
  )
}

export default VideosPage
